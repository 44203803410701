import Model from '~/models/Model'

export default class MyFreelanerProfile extends Model {
  static get resource () {
    return 'freelancer/my-profile'
  }

  static latestHiredNotice () {
    return this.axios().get('latest-hired-notice')
  }

  static closeHiredNotice () {
    return this.axios().delete('latest-hired-notice')
  }

  formatPrices () {
    [
      'full_time_price', 'part_time_price', 'hourly_price',
    ].forEach(field => this.formatPrice(field))
  }

  formatPrice (field) {
    const data = this.data

    if (data[field] === '' || data[field] < 1) {
      data[field] = null
    } else {
      data[field] = data[field].replace(/,/g, '')
    }
  }

  saveBio (options = {}) {
    this.formatPrices()
    return this.save(options)
  }
}
