<template>
  <div v-show="shouldShowPasswordRequirements" class="password-strength">
    <span>Your password should contain the following:</span>
    <ul>
      <template v-for="(contain, id) in passwordMustContain" >
        <li
          :id="id"
          :key="id"
          :class="[{ validated: !contain.error }, 'has_error' ]"
          v-text="contain.errorMsg"
        />
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    password: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      passwordMustContain: {
        'has-lowecase-letter': {
          error: true,
          errorMsg: 'One or more lowercase letters',
        },
        'has-uppercase-letter': {
          error: true,
          errorMsg:
            'One or more uppercase letters',
        },
        'has-number': {
          error: true,
          errorMsg: 'One or more numbers',
        },
        'min-8-character': {
          error: true,
          errorMsg: 'Minimum 8 characters',
        },
        'special-char': {
          error: false,
          errorMsg: 'Any special character is allowed',
        },
      },
    }
  },

  computed: {
    shouldShowPasswordRequirements () {
      return !! this.password
    },
  },

  watch: {
    password () {
      if (this.shouldShowPasswordRequirements) {
        this.checkPasswordStrength()
      }
    },
  },

  methods: {
    checkPasswordStrength () {
      const password = this.password

      let error = false

      this.passwordMustContain['has-lowecase-letter'].error = false

      const lowercaseRegex = new RegExp('^(?=.*[a-z])')
      if (! lowercaseRegex.test(password)) {
        this.passwordMustContain['has-lowecase-letter'].error = true
        error = true
      }

      this.passwordMustContain['has-uppercase-letter'].error = false
      const uppercaseRegex = new RegExp('^(?=.*[A-Z])')
      if (! uppercaseRegex.test(password)) {
        this.passwordMustContain['has-uppercase-letter'].error = true
        error = true
      }

      this.passwordMustContain['has-number'].error = false
      const numberRegex = new RegExp('^(?=.*[0-9])')
      if (! numberRegex.test(password)) {
        this.passwordMustContain['has-number'].error = true
        error = true
      }

      this.passwordMustContain['min-8-character'].error = false
      const longRegex = new RegExp('^(?=.{8,})')
      if (! longRegex.test(password)) {
        this.passwordMustContain['min-8-character'].error = true
        error = true
      }

      // this.passwordMustContain['special-char'].error = false
      // const specialChar = new RegExp('^(?=.*[-!$%^&*()_+|~=`{}\\[\\]:\\/;<>?,.@#])')
      // if (! specialChar.test(password)) {
      //   this.passwordMustContain['special-char'].error = true
      // }

      if (! password) {
        return false
      }

      return error
    },
  },
}
</script>
