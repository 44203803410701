<template>
  <div>
    <el-col :span="24">
      <picture-input 
        v-if="! changed || ! validatedImage"
        ref="pictureInput"
        margin="16" 
        accept="image/jpeg,image/png,image/2q=="
        size="10" 
        button-class="btn"
        :custom-strings="{ drag: '' }"
        :prefill="src"
        hide-change-button
        @change="onChange"
      ></picture-input>
    </el-col>

    <div v-if="changed && validatedImage">
      <el-col :xs="24" :md="{span: 12, offset: 6}">
        <div class="cropper-container">
          <img :id="cropperId" :src="validatedImage" />
        </div>
      </el-col>
      <el-col :span="24" class="text-center">
        <br/>
        <el-tooltip effect="dark" content="Cancel" placement="top">
          <el-button icon="el-icon-error" plain @click="clear"></el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="Rotate to the left" placement="top">
          <el-button icon="el-icon-refresh-left" plain @click="cropper.rotate(-90)"></el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="Rotate to the right" placement="top">
          <el-button icon="el-icon-refresh-right" plain @click="cropper.rotate(90)"></el-button>
        </el-tooltip>
        <el-tooltip effect="dark" content="Upload Image" placement="top">
          <el-button type="warning" icon="el-icon-upload" plain @click="upload">Upload</el-button>
        </el-tooltip>
      </el-col>
    </div>
  </div>
</template>

<script>
  import PictureInput from 'vue-picture-input'
  import Cropper from 'cropperjs'
  import 'cropperjs/dist/cropper.css'

  export default {
    components: {
      PictureInput,
    },

    props: {
      minWidth: {
        type: Number,
        default: 200,
      },
      minHeight: {
        type: Number,
        default: 200,
      },
      src: {
        type: String,
        default: null,
      },
      cropperId: {
        type: String,
        default () {
          // assures a unique id for the cropper element
          return 'cropper-' + Math.floor(Math.random() * 1000)
        },
      },
      inputText: {
        type: String,
        default: 'Click here or drop the image you want to upload',
      },
    },

    data () {
      return {
        validatedImage: this.src,
        cropper: null,
        changed: false,
      }
    },

    mounted () {
      setTimeout(() => this.setInputText(this.inputText), 1500)
    },

    watch: {
      inputText (newValue) {
        this.setInputText(newValue)
      },

      validatedImage (newValue) {
        // process if there's a validatedImage
        if (newValue) {
          this.initializeCropper()
          this.changed = true
        }
      },

      src (newValue) {
        // if src was changed from the outside, set changed to false and update validatedImage
        this.validatedImage = newValue

        // nextTick so we override validatedImage watcher
        this.$nextTick(() => this.changed = false)
      }
    },

    methods: {
      setInputText (string) {
        this.$refs.pictureInput.strings.drag = string
      },

      onChange (base64Image) {
        localStorage.setItem('uploadPhoto', 0); // store the uploading status in localstorage

        // initialize FileReader
        const reader = new FileReader

        // save file object for use later
        const pictureInput = this.$refs.pictureInput
        const file = pictureInput.file

        // read contents of image file
        reader.readAsDataURL(file)

        reader.onload = e => {
          // initialize Image object
          const image = new Image

          // set the Base64 string return from FileReader as source.
          image.src = e.target.result

          // save vue instance for use inside this-bound onload function
          const vm = this
                 
          // validate the height and width
          image.onload = function () {
            var height = parseInt(this.height)
            var width = parseInt(this.width)
            
            if (height < vm.minHeight || width < vm.minWidth) {
              // remove the file after 200ms so it flickers and lets the user know that it was removed
              setTimeout(pictureInput.removeImage, 200)

              // vm.setInputText(`Height and Width must be at least 200px. Please select another image.`)
              vm.$message.error("Height and Width must be at least 200px. Please select another image.");
              
            } else {
              vm.validatedImage = base64Image
            }
          }
        }

        this.$emit('clearErrorMessage');
      },

      initializeCropper () {
        this.$nextTick(() => {
          // initialize cropper only when validated image has been rendered by Vue
          this.cropper = new Cropper(document.getElementById(this.cropperId), {
            aspectRatio: 1,
          })
        })
      },

      clear () {
        this.validatedImage = null
        this.clearError();
        localStorage.removeItem('uploadPhoto'); // remove the uploading status in localstorage
        // this.$refs.pictureInput.removeImage()
      },

      upload () {
        this.cropper.getCroppedCanvas({
          width: this.minWidth,
          height: this.minHeight,
        }).toBlob(blob => this.$emit('upload', blob))
        localStorage.setItem('uploadPhoto', 1); // store the uploading status in localstorage
        this.clearError();
      },

      clearError() {
        this.$emit('clearErrorMessage');
      }
    },
  }
</script>

<style lang="scss">
  
  .picture-input {
    height: 200px;
    width:200px;
  }
  #picture-input{
    .picture-preview {
      width: 200px !important;
      height: 200px !important;
      z-index: 2001 !important;
    }
    .preview-container {
     height: 200px !important;
      width:200px !important; 
    }
    .picture-inner{
      top: -216px !important;
      border: 2px dashed rgba(66,66,66,.15);
      width: calc(100% - 1em);
      height: calc(100% - 1em);
      z-index: 2002 !important;
    }
    .picture-inner .picture-inner-text{
      font-size: 14px;
    }
  }
  .el-message.el-message--error .el-message__content{
    line-height: 1.6 !important;
  }
  .el-message.el-message--error {
    z-index: 3000 !important;
  }
</style>
