<template>
  <div>
    <photo-uploader
      v-if="src"
      :src="src"
      :min-height="200"
      :min-width="200"
      @upload="upload"
      @clearErrorMessage="clearErrorMessage"
    />
    <photo-uploader
      v-else
      :min-height="200"
      :min-width="200"
      @upload="upload"
      @clearErrorMessage="clearErrorMessage"
    />
  </div>
</template>

<script>
import url from '~/helpers/url'
import MyFreelancerProfile from '~/models/MyFreelancerProfile'
import PhotoUploader from '~/components/PhotoUploader.vue'

export default {
  components: { PhotoUploader },

  data () {
    return {
      Profile: null,
    }
  },

  beforeMount() {
    this.Profile = new MyFreelancerProfile('profile-photo');
  },

  computed: {
    src () {
      if (! this.profile.profile_photo) {
        return null
      }
      return url(this.profile.profile_photo)
    },

    profile () {
      return this.Profile.data
    },
  },

  methods: {
    upload (file) {
      const formData = new FormData()
      formData.append('profile_photo', file)

      MyFreelancerProfile.axios().post('profile-photo', formData, {
        headers: MyFreelancerProfile.headers({ 'Content-Type': 'multipart/form-data' }),
      }).then(response => {
        // avatar will be replaced by the uploaded public image URL
        this.Profile.data.profile_photo = response.data.profile_photo;
        this.$store.commit('FREELANCER_UPDATE_PROFILE_PHOTO', response.data.profile_photo);
        this.$emit('upload');
      }).catch(error => {
        this.Profile.errors = error.response.data.errors
        this.$emit('upload')
      })
    },

    clearErrorMessage() {
      this.$emit('clearErrorMessage');
    }

  },
}
</script>
