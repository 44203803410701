<template>
  <el-alert
    v-if="Object.keys(errors).length > 0"
    type="error"
    title="The following is required:"
    :closable="false"
    center="center"
    class="mb-4 tabbio-alert mx-auto mt-1"
    effect="dark"
  >
    <ul>
      <li v-for="title in errors.title" :key="title">
        <i class="el-icon-error" /> {{ title }}
      </li>
      <li v-for="intro in errors.intro" :key="intro">
        <i class="el-icon-error" /> {{ intro }}
      </li>
      <li v-for="description in errors.description" :key="description">
        <i class="el-icon-error" /> {{ description }}
      </li>
      <li v-if="errors.full_time_ava || errors.part_time_ava || errors.hourly_ava">
        <i class="el-icon-error" /> Availability
      </li>
      <li v-for="fulltimePrice in errors.full_time_price" :key="fulltimePrice">
        <i class="el-icon-error" /> {{ fulltimePrice }}
      </li>
      <li v-for="partTimePrice in errors.part_time_price" :key="partTimePrice">
        <i class="el-icon-error" /> {{ partTimePrice }}
      </li>
      <li v-for="hourlyPrice in errors.hourly_price" :key="hourlyPrice">
        <i class="el-icon-error" /> {{ hourlyPrice }}
      </li>
      <li v-if="errors.full_time_price || errors.part_time_price || errors.hourly_price">
        <i class="el-icon-error" /> Pricing
      </li>
    </ul>
  </el-alert>
</template>

<script>
export default {
  props: {
    errors: {
      type: Object,
      required: true,
    },
  },
}
</script>
