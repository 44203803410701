<template>
  <el-form>
    <div v-show="! hideBio">
      <el-form-item prop="title">
        <p class="main mb-1 mt-2 is-required">Title</p>
        <p class="description">Enter a single sentence description of your professional skills/experience (e.g. Expert Web Designer with Ajax experience)</p>
        <el-input v-model="profile.title" min-length="6" maxlength="80" show-word-limit />
      </el-form-item>
      <el-form-item prop="intro">
        <p class="main mb-1 mt-2 is-required">Introduction</p>
        <p class="description">Give employers a summary of why they should pick you.</p>
        <el-input v-model="profile.intro" type="textarea" min-length="30" maxlength="130" show-word-limit :rows="4" />
      </el-form-item>
      <el-form-item prop="description">
        <p class="main mb-1 mt-2 is-required">Bio</p>
        <p class="description mb-2">Use this space to show clients you have the skills and experience they're looking for.</p>
        <ul class="description mb-3">
          <li>Describe your strengths and skills</li>
          <li>Highlight projects, accomplishments and education</li>
          <li>Keep it short and make sure it's error-free.</li>
        </ul>
        <VueTrix v-model="profile.description" input-id="bio" />
        <span class="character-count" :class="{ 'text-danger': characterCount > 2000 }">{{ characterCount }}/2000</span>
      </el-form-item>
    </div>
    <div class="price-range">
      <p class="mt-5 mb-1 is-required">Pricing & Availability</p>
      <p class="mb-4 description">Please set your price for the type of work you are available for. Please note that if you do not set a price, you will not appear when employers search for freelancers for that type of work.</p>

      <el-checkbox-group v-if="hasLoaded" v-model="prices" class="prices freelancer-pricing">
        <el-row class="mb-3" :gutter="15">
          <el-col :xs="6" :sm="5" :md="4">
            <el-checkbox label="Full-time" />
          </el-col>
          <el-col :span="9" :sm="10" :md="8" :lg="6">
            <input
              v-model="profile.full_time_price"
              v-cleave="{numeral: true, numeralThousandsGroupStyle: 'thousand'}"
              type="text"
              class="w-100 form-control text-center"
            >
          </el-col>
          <el-col :span="7" :sm="4" :md="6" :lg="7">
            <p class="mb-0 mt-2 usd">USD / month</p>
          </el-col>
        </el-row>
        <el-row class="mb-3" :gutter="15">
          <el-col :xs="6" :sm="5" :md="4">
            <el-checkbox label="Part-time" />
          </el-col>
          <el-col :span="9" :sm="10" :md="8" :lg="6">
            <input
              v-model="profile.part_time_price"
              v-cleave="{numeral: true, numeralThousandsGroupStyle: 'thousand'}"
              type="text"
              class="w-100 form-control text-center"
            >
          </el-col>
          <el-col :span="7" :sm="4" :md="6" :lg="7">
            <p class="mb-0 mt-2 usd">USD / month</p>
          </el-col>
        </el-row>
        <el-row class="mb-3" :gutter="15">
          <el-col :xs="6" :sm="5" :md="4">
            <el-checkbox label="Hourly" />
          </el-col>
          <el-col :span="9" :sm="10" :md="8" :lg="6">
            <input
              v-model="profile.hourly_price"
              v-cleave="{numeral: true, numeralThousandsGroupStyle: 'thousand'}"
              type="text"
              class="w-100 form-control text-center"
            >
          </el-col>
          <el-col :span="7" :sm="4" :md="6" :lg="7">
            <p class="mb-0 mt-2 usd">USD / hour</p>
          </el-col>
        </el-row>
      </el-checkbox-group>
      <!-- </el-checkbox-group> -->
    </div>
  </el-form>
</template>

<script>
import VueTrix from 'vue-trix'

export default {
  components: { VueTrix },

  props: {
    profile: {
      type: Object,
      required: true,
    },

    hideBio: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      prices: [],
    }
  },

  computed: {
    characterCount () {
      if (! this.profile.description) {
        return 0
      }

      return this.stripHtmlTags(this.profile.description).length
    },

    hasLoaded () {
      return !! this.profile.freelancer_profile_id
    },
  },

  watch: {
    prices () {
      this.setAvailabilityState()
    },

    hasLoaded () {
      this.setPricingCheckboxState()
      this.setAvailabilityState()
    },

    // profile: {
    //   handler (v) {
    //     if (v.full_time_ava === 0) {
    //       this.profile.full_time_ava = null
    //     }
    //     if (v.part_time_ava === 0) {
    //       this.profile.part_time_ava = null
    //     }
    //     if (v.hourly_ava === 0) {
    //       this.profile.hourly_ava = null
    //     }
    //   },
    //   immediate: true,
    // },
  },

  methods: {
    stripHtmlTags (str) {
      if ((str === null) || (str === '')) { return false } else { str = str.toString() }
      return str.replace(/<[^>]*>/g, '')
    },

    setPricingCheckboxState () {
      const prices = this.prices
      const profile = this.profile

      if (profile.full_time_ava && ! prices.includes('Full-time')) {
        prices.push('Full-time')
      }

      if (profile.part_time_ava && !
              prices.includes('Part-time')) {
        prices.push('Part-time')
      }

      if (profile.hourly_ava && !
              prices.includes('Hourly')) {
        prices.push('Hourly')
      }
    },

    setAvailabilityState () {
      const prices = this.prices
      this.profile.full_time_ava = prices.includes('Full-time') ? 1 : null
      this.profile.part_time_ava = prices.includes('Part-time') ? 1 : null
      this.profile.hourly_ava = prices.includes('Hourly') ? 1 : null
    },
  },
}
</script>

<style lang="css" scoped>
.bio p.main{
  font-size: 14px;
  color: #333;
  //color: #8F8F8F;
  font-weight: bold;
}
.bio .description{
  font-size: 13px;
  color: #333;
  font-weight: normal;
  line-height: 26px;
}
.price-range .el-input {
  width: 200px;
}
.el-form {
  position: relative;
}
.price-range .el-input {
  width: 200px;
}
.price-range p.black-text{
  color: #333;
}
.price-range .el-checkbox {
  line-height: 40px;
  margin-bottom: 0;
}
.el-input__inner {
    position: relative;
}
.el-icon-view {
    color: #409EFF;
    cursor: pointer;
    font-size: 18px;
    position: absolute;
    right: 12px;
    top: 12px;
}
.profile-alert {
  text-align: left;
  max-width: 400px;
  width: 100%;
  margin: 0 auto 25px;
  /*margin: 3% 3% 1% 25px;*/
  font-weight: bold;

}
.profile-alert ul{
  margin-bottom: 5px;
}
.profile-alert ul li {
  list-style: none;
}

@media (max-width: 1199px){
  .hidden-sm {
    display: none;
  }
}
@media (max-width: 992px) {
  .hidden-sm {
    display: none;
  }
}
@media (max-width: 400px) {
  .prices>>>.el-checkbox__label {
    font-size: 12px;
  }
}
</style>
